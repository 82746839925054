<template>
    <div class="text-center">
        <h4 class="mt-8 text-center">שתפו את AmIsraeLive.com</h4>
        <v-row class="mx-auto mt-2"
            justify="center">
            
            <ShareNetwork
                v-for="platform in platforms"
                :network="platform.network"
                :key="platform.network"
                :url="share.url"
                :title="share.title"
                :description="share.description"
                :quote="share.quote"
                :hashtags="share.hashtags"
                :twitterUser="share.twitterUser">


                
                <v-btn class="ma-1" x-small fab dark :color="platform.color">
                    <v-icon small dark color="white">{{ platform.icon }}</v-icon>
                </v-btn>
                
                
            </ShareNetwork>
        </v-row>
    </div>
</template>

<script>

export default {
    data() {
        return {
            networks: [
                { network: 'email', name: 'אימייל', icon: 'mdi-email', color: '#333333' },
                { network: 'whatsapp', name: 'ווטסאפ', icon: 'mdi-whatsapp ', color: '#25d366' },
                { network: 'facebook', name: 'פייסבוק', icon: 'mdi-facebook', color: '#1877f2' },
                { network: 'sms', name: 'סמס', icon: 'mdi-message-processing', color: '#EF6C00' },
                { network: 'telegram', name: 'טלגרם', icon: 'mdi-telegram', color: '#0088cc' },
                { network: 'twitter', name: 'טוויטר', icon: 'mdi-twitter', color: '#1da1f2' },
                { network: 'linkedin', name: 'לינקדאין', icon: 'mdi-linkedin', color: '#007bb5' },
                { network: 'skype', name: 'סקייפ', icon: 'mdi-skype', color: '#00aff0' },
                { network: 'reddit', name: 'רדיט', icon: 'mdi-reddit', color: '#ff4500' },
                { network: 'pinterest', name: 'פינטרסט', icon: 'mdi-pinterest', color: '#bd081c' }
            ],
            sharePlatforms: ['email', 'whatsapp', 'telegram', 'facebook', 'twitter', 'sms'],
            share: {
                url: 'https://AmIsraeLive.com',
                title: 'עם ישראל חי',
                description: 'תפילות וסגולות מיוחדות'
            }
        }
    },
    computed: {
        platforms: {
            get() {
                let userSharePlatforms = []
                this.sharePlatforms.forEach(item => {
                    this.networks.forEach(network => {
                        if(network.network === item) {
                            userSharePlatforms.push(network)
                        }
                    }) 
                })
                return userSharePlatforms
            }            
        },
    }
}

</script>

<style scoped>

 *, ::before, ::after{
  text-decoration: none !important;
}

</style>